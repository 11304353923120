<template>
  <div class="container-page">
    <p class="title-inner">ПИИ с Россией</p>
    <div class="mb-16">
      <ButtonStock
        :disabled="importInProgress"
        :title="!importInProgress ? 'Загрузить из Excel' : 'Загрузка...'"
        @click="importExcel()"
      />
    </div>
    <div class="mb-4">
      <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
    </div>
    <div class="mb-4">
      <SwitchComponent
        @switchHandler="switchHand($event)"
        textTrue="Входящие ПИИ в Россию"
        textFalse="Исходящие ПИИ из России"
        className="big"
      />
    </div>
    <div class="mb-16">
      <SwitchComponent
        @switchHandler="switchHandDirect($event)"
        textTrue="Прямые инвестиции"
        textFalse="Накопленные инвестиции"
        className="big"
      />
    </div>

    <div>
      <div class="table-container mb-16 js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th>Год</th>
              <th>Страна</th>
              <th>На дату</th>
              <th>Объем, млн $ США</th>
            </tr>
            <tr>
              <th class="filter">
                <FilterInput
                  type="year"
                  v-model="filter.year"
                  placeholder="Год"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  type="select2"
                  :select2-options="countries"
                  :select2-settings="select2Settings"
                  v-model="filter.countryId"
                  placeholder="(Все)"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter"></th>
              <th class="filter">
                <div class="range">
                  <FilterInput
                    type="number"
                    v-model="filter.valueFrom"
                    class="mr-2"
                    placeholder="от"
                    v-on:valueChangedDebounced="changeFilter()"
                  />
                  <FilterInput
                    type="number"
                    v-model="filter.valueTo"
                    placeholder="до"
                    v-on:valueChangedDebounced="changeFilter()"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="page.items?.length && !isLoading">
            <tr v-for="item in page.items" :key="item.id" @dblclick="viewCard(item)">
              <td>{{ item.year }}</td>
              <td>{{ item.country?.nameFull || item.country?.name }}</td>
              <td>{{ $momentFormat(item.onDate, 'DD MMMM YYYY') }}</td>
              <td>
                <template v-if="item.isLess500">менее 0,5</template>
                <template v-else-if="item.isConfidencial">конфид.</template>
                <template v-else>{{ item.value?.toFixed(2) }}</template>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="isLoading">
            <tr>
              <td class="text-center" colspan="5">Загрузка...</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="5">Нет данных.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="page.pagesTotal > 1">
        <Pagination
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>
    <section>
      <ModalComponent v-model="importModal" title="Импорт файла" @close="closeModal">
        <ImportModal @doimport="doImport" @close="closeModal" />
      </ModalComponent>
    </section>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import Utils from '@/common/utils';

  import SwitchComponent from '@/components/buttons/switchComponent';
  import ImportModal from '@/components/modals/ImportModal';

  import FilterInput from '../../../common/components/FilterInput';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import Api from '../api/index';

  export default {
    name: 'InvestmentRussia',
    components: { ImportModal, ModalComponent, ButtonStock, FilterInput, SwitchComponent, DefaultFilter },
    mixins: [filtermanager],
    data() {
      return {
        route: 'investmentrussia',
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          isIncomingInRussia: true,
          isDirect: true,
        },

        select2Settings: Constants.select2Settings,
        countries: [],

        filter: {
          year: null,
          countryId: null,
          valueFrom: null,
          valueTo: null,
        },
        importInProgress: false,
        importModal: false,
        isLoading: false,
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadPage();
        this.loadDictionaries();
      });
    },
    methods: {
      loadPage(append) {
        this.isLoading = !append;
        const params = Object.assign(this.filter, this.request);
        Api.search(this.route, params)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      loadDictionaries() {
        Utils.searchNsi('countrylistwithdata', {})
          .then((response) => {
            this.countries = response.data.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      importExcel() {
        this.importModal = true;
      },
      doImport(file) {
        this.closeModal();
        this.importInProgress = true;

        if (this.request.isDirect) {
          Api.uploadDirect(this.route, file)
            .then(() => {
              Constants.alert.fire('Импорт', 'Импорт успешно выполнен.', 'success');
              this.loadPage();
              this.importInProgress = false;
            })
            .catch((error) => {
              console.log(error?.response?.data);
              Constants.alert.fire('Импорт', 'Ошибка при импорте данных.', 'error');
              this.importInProgress = false;
            });
        } else
          Api.downloadFile(this.route, file)
            .then(() => {
              Constants.alert.fire('Импорт', 'Импорт успешно выполнен.', 'success');
              this.loadPage();
              this.importInProgress = false;
            })
            .catch((error) => {
              console.log(error?.response?.data);
              Constants.alert.fire('Импорт', 'Ошибка при импорте данных.', 'error');
              this.importInProgress = false;
            });
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      closeModal() {
        this.importModal = false;
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      switchHand(e) {
        this.request.isIncomingInRussia = e;
        this.changeFilter();
      },
      switchHandDirect(e) {
        this.request.isDirect = e;
        this.changeFilter();
      },
      viewCard(item) {
        this.$router.push({ name: 'InvestmentRussiaCard', params: { id: item.id } });
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
  };
</script>

<style scoped></style>
